export default {
  data() {
    return {
      logoItems: [
        {
          name: this.$i18n.locale === 'cs' ? 'doprava_zdarma' : 'doprava_zdarma--sk',
          path: this.$i18n.locale === 'cs' ? '/images/logo/doprava_zdarma.png' : '/images/logo/sk/doprava_zdarma.png',
          url:
            this.$i18n.locale === 'cs'
              ? 'https://eshop.nobilis.cz/stranka/doprava-a-postovne'
              : 'https://eshop.nobilis-tilia.sk/stranka/doprava-a-postovne',
        },
        {
          name: this.$i18n.locale === 'cs' ? 'darek_k_objednavce' : 'darek_k_objednavce--sk',
          path:
            this.$i18n.locale === 'cs'
              ? '/images/logo/darek_k_objednavce.png'
              : '/images/logo/sk/darek_k_objednavce.png',
          url:
            this.$i18n.locale === 'cs'
              ? 'https://eshop.nobilis.cz/stranka/darek-k-objednavce'
              : 'https://eshop.nobilis-tilia.sk/stranka/darcek-k-objednavke',
        },
        {
          name: this.$i18n.locale === 'cs' ? 'vernostni_odmeny' : 'vernostni_odmeny--sk',
          path:
            this.$i18n.locale === 'cs' ? '/images/logo/vernostni_odmeny.png' : '/images/logo/sk/vernostni_odmeny.png',
          url:
            this.$i18n.locale === 'cs'
              ? 'https://eshop.nobilis.cz/stranka/vernostni-system'
              : 'https://eshop.nobilis-tilia.sk/stranka/vernostny-system',
        },
        {
          name: this.$i18n.locale === 'cs' ? 'bezpecne_platby' : 'bezpecne_platby--sk',
          path: this.$i18n.locale === 'cs' ? '/images/logo/bezpecne_platby.png' : '/images/logo/sk/bezpecne_platby.png',
          url:
            this.$i18n.locale === 'cs'
              ? 'https://eshop.nobilis.cz/stranka/platba'
              : 'https://eshop.nobilis-tilia.sk/stranka/platba',
        },
        {
          name: this.$i18n.locale === 'cs' ? 'mobilni_aplikace' : 'sit_prodejnich_mist--sk',
          path:
            this.$i18n.locale === 'cs'
              ? '/images/logo/mobilni_aplikace.png'
              : '/images/logo/sk/sit_prodejnich_mist.png',
          url:
            this.$i18n.locale === 'cs'
              ? 'https://eshop.nobilis.cz/mobilni-aplikace'
              : 'https://eshop.nobilis-tilia.sk/kontakt#prodejci-partneri',
        },
      ],
    }
  },
}
